.news-detail {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 40%
  );

  &-title {
    display: block;
    margin-top: 24px !important;
  }

  &-image {
    border-radius: 40px 10px;
    overflow: hidden;
    height: 738px;
    margin-bottom: 24px;

    .image {
      width: 100%;
      height: 100%;

      img {
        object-fit: contain;
        object-position: center;
      }
    }
  }

  &-breadcrumbs {
    display: flex;
    flex-direction: row;
    margin-top: 44px;

    a {
      text-transform: capitalize;
      transition: all 150ms ease-in-out;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;

      &:focus {
        outline: none;
      }

      &:not(:last-child)::after {
        content: '>';
        margin: 0 12px;
      }

      &:hover {
        color: $secondary-500;
      }
    }

    &-current {
      color: $grey-500;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
    }
  }
}

@media #{$media-sm} {
  .news-detail {
    &-title {
      margin-top: 14px !important;
      line-height: 42px !important;
      font-size: 28px !important;
      line-height: 38.7px !important;
    }

    &-image {
      height: 320px;
      margin-bottom: 12px;
    }

    &-breadcrumbs {
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      padding: 12px 0;
      margin-top: 34px;

      a {
        font-size: 12px;
        line-height: 16px;

        &:focus {
          outline: none;
        }

        &:not(:last-child)::after {
          content: '>';
          margin: 0 8px;
        }
      }

      &-current {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
