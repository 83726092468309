.detay-content-layout {
  margin-bottom: 169px;

  .detay-content {
    &-top-image {
      height: 586px;
      width: 100%;
      margin-bottom: 24px;

      .image {
        width: 100%;
        height: 100%;
        background-color: #e2e8f0;
        border-radius: 40px 10px;

        img {
          object-fit: cover;
          object-position: center;
        }
      }
    }

    &-children {
      p {
        max-width: 80%;
        font-size: 16px;
        line-height: 28px;
        color: #475569;
      }
    }
  }
}

@media #{$media-xl} {
  .detay-content-layout {
    margin-bottom: 109px;

    .detay-content {
      &-top-image {
        height: 486px;
      }

      &-children {
        p {
          max-width: 100%;
        }
      }
    }
  }
}

@media #{$media-lg} {
  .detay-content-layout {
    .detay-content {
      &-top-image {
        height: 386px;
      }

      &-children {
        p {
          max-width: 100%;
        }
      }
    }
  }
}

@media #{$media-md} {
  .detay-content-layout {
    .detay-content {
      &-top-image {
        height: 286px;
      }

      &-children {
        p {
          max-width: 100%;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
}

@media #{$media-sm} {
  .detay-content-layout {
    margin-bottom: 64px;

    .detay-content {
      min-height: auto;
      &-top-image {
        height: 389px;
      }
    }
  }
}

@media #{$media-xs} {
  .detay-content-layout {
    .detay-content {
      &-top-image {
        height: 219px;
      }
    }
  }
}
