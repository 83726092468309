.filter-calendar {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 14px 24px;
  border-radius: 20px 20px 20px 8px;
  border: 1px solid #f1f5f9;
  background-color: #fff;
  min-width: 171px;

  .clear-date-icon {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 20px;
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    cursor: pointer;
  }

  &.selected {
    min-width: 267px;
    .clear-date-icon {
      visibility: visible;
      pointer-events: auto;
      opacity: 1;
    }

    .react-datepicker__input-container {
      input {
        color: $secondary-500;
        max-width: fit-content;
        padding-right: 16px;
        user-select: none;
      }
    }

    .c-icon {
      color: $secondary-500;
    }
  }

  .react-datepicker__input-container {
    input {
      all: inherit;
      margin: 0;
      padding: 0;
      cursor: pointer;
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      color: #362e60;
      max-width: 125px;
      padding-right: 8px;

      &:focus {
        outline: none;
        border: none;
        caret-color: transparent;
      }

      &::placeholder {
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        color: #362e60;
        max-width: 125px;
      }
    }
  }

  .c-icon {
    font-weight: 400;
  }

  .react-datepicker-popper {
    top: 12px !important;
  }

  .react-datepicker {
    padding: 12px;
    border-radius: 8px 20px;
    border: 1px solid #e2e8f0;
    font-family: inherit;

    .react-datepicker__header {
      background-color: transparent;
      border-bottom: none;

      .react-datepicker__current-month {
        font-size: 16px;
        margin-bottom: 15px;
      }

      .react-datepicker__day-names {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-bottom: 0;
        .react-datepicker__day-name {
          font-size: 14px;
          font-weight: bold;
          line-height: 18px;
          color: #cbd5e1;
          margin: 6px;
        }
      }
    }

    .react-datepicker__month {
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;

      .react-datepicker__day {
        font-size: 14px;
        font-weight: bold;
        color: #362e60;
        margin: 6px;

        &--disabled {
          color: #cbd5e1;
        }

        &.react-datepicker__day--outside-month {
          color: #cbd5e1;
        }

        &.react-datepicker__day--selected,
        &.react-datepicker__day--keyboard-selected {
          color: #ed3c6b;
          background-color: transparent;

          &.react-datepicker__day--today {
            color: black;
          }

          &.react-datepicker__day--range-start,
          &.react-datepicker__day--selecting-range-start {
            color: #fff !important;
            background-color: #ed3c6b;
          }
        }
      }
    }

    .react-datepicker__navigation {
      top: 17px;

      &--previous {
        left: 17px;
      }

      &--next {
        right: 17px;
      }

      .react-datepicker__navigation-icon::before {
        border-color: #94a3b8;
        border-width: 2px 2px 0 0;
      }
    }

    .react-datepicker__day--in-range,
    .react-datepicker__day--in-selecting-range {
      color: #fff !important;
      background-color: #ed3c6b;
    }
  }

  .react-datepicker__close-icon {
    &::after {
      color: #fff !important;
      background-color: #ed3c6b !important;
    }
  }

  .react-datepicker__year-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    min-width: 180px;
    margin-top: 14px;
    max-width: 100%;

    .react-datepicker__year-text {
      width: auto;
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
      color: $grey-700;
      padding: 6px 8px;
      margin: 8px 0;
      min-width: 40px;

      &--disabled {
        color: #ccc;
        pointer-events: none;
      }
    }

    .react-datepicker__year-text--keyboard-selected {
      background-color: transparent;
    }
    .react-datepicker__year-text--selected {
      color: #000;
      color: #fff !important;
      background-color: #ed3c6b !important;
    }
  }

  &.archive-calendar {
  }

  &.mobile-date-picker-year {
    .react-datepicker__year-wrapper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      max-width: 100%;

      .react-datepicker__year-text {
        padding: 6px 8px;
        margin: 12px 0;
      }
    }
  }
}
