.about-us-image-gallery {
  &__wrapper {
    height: 300vh;
    position: relative;
    z-index: 2;
    overflow-x: initial;
  }

  height: calc(var(--app-height) - 100px);
  position: sticky;
  top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  @media ($media-md) {
    height: calc(var(--app-height) - 50px);
    top: 50px;
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    position: relative;
    z-index: 3;
    padding: 0 20px;
    max-width: 720px;
    margin: 0 auto;

    .bracket-left {
      display: flex;
      justify-content: flex-end;

    }

    .bracket-right {
      display: flex;
      justify-content: flex-start;
    }

    svg {
      width: 100%;
    }
  }

  &__gallery {
    position: relative;
    overflow: hidden;
    height: 330px;

    &__slogan {
      font-size: 84px;
      font-weight: 400;
      color: $secondary-500;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 50%;
      top: 0;

      span {
        display: block;
        border: 1px solid $grey-200;
        padding: 14px 34px 14px 34px;
        position: relative;


        &:nth-child(1) {
          border-radius: 10px 40px 0px 40px;
          left: -80px;
        }

        &:nth-child(2) {
          border-radius: 0px 10px 40px 10px;
          right: -80px;
        }
      }
    }

    &__items {
      white-space: nowrap;
      overflow-x: hidden;
      position: absolute;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      gap: 25px;

      &__item {
        width: 413px;
        height: 100%;

        background-color: $grey-200;
        border-radius: 10px 40px 10px 40px;
        overflow: hidden;


        .image {
          width: 100%;
          height: 100%;
          cursor: pointer;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover !important;

        }

        @media (max-width: 413px) {
          width: 95vw;
        }
      }
    }

    @media ($media-md) {
      &__slogan {
        width: 100%;
        font-size: 55px;

        span {
          &:nth-child(1) {
            left: -40px;
          }

          &:nth-child(2) {
            right: -40px;
          }
        }

      }

      &__items {
        left: 100%;
      }


    }

  }

  &__modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: var(--app-height);
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9999;

    picture {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .image {
        width: 100%;
        height: 100%;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain !important;
        }
      }
    }

  }

}