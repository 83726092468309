.about-us-common {
  &__section-header {
    padding: 0 60px;
    max-width: 1223px;
    margin-bottom: 24px;
    margin-top: 180px;

    h1 {
      font-weight: 400;
      font-size: 75px;
      margin: 0;
      color: $secondary-500;
      line-height: 115px;
    }

    p {
      font-weight: 400;
      font-size: 24px;
      margin: 0;
      color: $primary-500;
      line-height: 37px;
      color: $grey-600;
    }

    .bracket-left {
      display: flex;
      justify-content: flex-start;
      max-width: 350px;
      width: 100%;

      svg {
        width: 100%;
      }
    }
  }
}

@media ($media-md) {
  .about-us-common {
    &__section-header {
      padding: 0 10px;

      h1 {
        font-size: 48px;
        line-height: 70px;
        line-height: 60px;
        margin: 14px auto;
      }
    }

    &__videos {
      left: 10px;
    }
  }
}

.about-us-section {
  // margin-bottom: 180px;
}
