.festival-gallery {
  position: relative;
  &__photos {
    height: 600px;
    position: relative;
    &__item {
      position: absolute;
      left: 0;
      top: 0;
      padding: 6px;
      box-sizing: border-box;
      figure {
        overflow: hidden;
        width: 100%;
        height: 100%;
        a {
          display: block;
          width: inherit;
          height: inherit;
          .image,
          img,
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .c-icon {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 29px;
            z-index: 1;
            color: light-neutral(0);
          }
        }
      }
      &:nth-child(1) {
        width: 30%;
        height: 100%;
        figure {
          border-radius: 90px 90px 10px 90px;
        }
      }
      &:nth-child(2) {
        width: 40%;
        height: 50%;
        left: 30%;
        figure {
          border-radius: 90px 90px 10px 10px;
        }
      }
      &:nth-child(3) {
        width: 30%;
        height: 50%;
        left: 70%;
        figure {
          border-radius: 90px 90px 10px 10px;
        }
      }
      &:nth-child(4) {
        width: 35%;
        height: 50%;
        left: 30%;
        top: 50%;
        figure {
          border-radius: 10px 10px 90px 10px;
        }
      }
      &:nth-child(5) {
        width: 35%;
        height: 50%;
        left: 65%;
        top: 50%;
        figure {
          border-radius: 10px 10px 90px 90px;
        }
      }
    }
  }
}


@media #{$media-sm} {
  .festival-gallery {
    &__photos {
      height: 254px;
      &__item {
        &:nth-child(1) {
          figure {
            border-radius: 52.14px 52.14px 5.79px 52.14px;
          }
        }
        &:nth-child(2) {
          figure {
            border-radius: 52.14px 52.14px 5.79px 5.79px;
          }
        }
        &:nth-child(3) {
          figure {
            border-radius: 52.14px 52.14px 5.79px 5.79px;
          }
        }
        &:nth-child(4) {
          figure {
            border-radius: 5.79px 5.79px 52.14px 5.79px;
          }
        }
        &:nth-child(5) {
          figure {
            border-radius: 5.79px 5.79px 52.14px 52.14px;
          }
        }
      }
    }
  }
}
