$headerTopPadding: 130px;

.banner {
  width: 100%;
  text-align: center;
  padding-top: $headerTopPadding;
  padding-bottom: 30px;
  font-size: 16px;
  line-height: 28.8px;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  color: $grey-600;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $secondary-500;
  }
  h1 {
    font-size: 104px;
    line-height: 118px;
    font-weight: 700;
    margin-top: 32px;
  }
  &__arrow-down {
    margin: 24px 0;
  }

  &.detail-banner {
    padding-top: calc($headerTopPadding + 121px);
    padding-bottom: 2rem;

    .row {
      margin-top: 12px;
      margin-bottom: 12px;

      h1 {
        max-width: 80%;
      }
    }
  }
}

@media #{$media-xl} {
  .banner {
    background-size: cover;
    &.detail-banner {
      h1 {
        font-size: 74px;
        line-height: 82px;
      }
    }
  }
}

@media #{$media-lg} {
  .banner {
    background-size: cover;
    h1 {
      font-size: 70px;
      line-height: 90px;
    }
    &.detail-banner {
      padding-top: calc($headerTopPadding + 80px);
      h1 {
        font-size: 64px;
        line-height: 72px;
      }
    }
  }
}

@media #{$media-md} {
  .banner {
    background-size: cover;
    padding-top: 67px;
    h1 {
      font-size: 34px;
      line-height: 52.7px;
    }
    p {
      font-size: 12px;
    }
    &.detail-banner {
      padding-top: $headerTopPadding;
      h1 {
        font-size: 58px;
        line-height: 68px;
        max-width: 100%;
      }
    }
  }
}

@media #{$media-sm} {
  .banner {
    background-size: cover;
    &.detail-banner {
      padding-top: 80px;
      padding-bottom: 18px;
      h1 {
        font-size: 34px;
        line-height: 52px;
      }
      .socials-share {
        display: none;
      }
    }
  }
}
