.subscribe {
  margin: 119.55px 0;
  &__form {
    position: relative;
    &__in {
      border-radius: 40px 10px 40px 10px;
      padding: 54px 100.5px;
      background-color: $primary-500;
      box-sizing: border-box;
      color: light-neutral(0);
      text-align: center;
      &__title {
        font-size: 42px;
        font-weight: bold;
        line-height: 58px;
        margin-bottom: 25px;
      }
      &__desc {
        line-height: 28.8px;
        font-size: 16px;
      }
      &__group {
        position: relative;
        margin-top: 44px;
        overflow: hidden;
        input {
          width: 100%;
          border: none;
          box-sizing: border-box;
          border-radius: 20px 8px 20px 8px;
          padding: 0 150px 0 25px;
          outline: none;
          font-weight: bold;
          color: $secondary-500;
          font-size: 14px;
          line-height: 64px;
          &::placeholder {
            color: $primary-500;
            opacity: 1;
          }

          &::-ms-input-placeholder {
            color: $primary-500;
          }
        }
        button {
          position: absolute;
          right: 8px;
          top: 8px;
          border-radius: 20px 4px 20px 4px;
          font-size: 14px;
          min-width: 128px;
          padding: 0 15px !important;
          .button-text {
            line-height: 48px !important;
          }
        }
        &__alert {
          position: absolute;
          background-color: $primary-400;
          z-index: 1;
          width: 100%;
          height: 100%;
          padding: 0 25px;
          box-sizing: border-box;
          border-radius: 20px 8px 20px 8px;
          top: 0;
          left: 0;
          line-height: 64px;
          text-align: left;
          pointer-events: none;
          opacity: 0;
          color: light-neutral(0);
          font-weight: 700;
          button {
            padding: 10px 40px 10px 40px;
          }
          &--active {
            opacity: 1;
            pointer-events: auto;
          }
          &--success {
            color: #78af64;
            background-color: $soft-green-dark;
          }
          &--error,
          &--duplicate {
            color: $secondary-500;
            background-color: $soft-red-dark;
          }
        }
      }
    }

    &::before {
      content: '';
      width: 336px;
      height: 288px;
      position: absolute;
      top: -77px;
      left: -129px;
      z-index: -1;
      background-image: url('https://cdn.tga.net.tr/glomil-prod/Root/adana-kultur-yolu/subscribe-before.svg');
      background-size: cover;
    }
    &::after {
      content: '';
      width: 311px;
      height: 415px;
      position: absolute;
      bottom: -71.45px;
      right: -119.53px;
      z-index: -1;
      background-image: url('https://cdn.tga.net.tr/glomil-prod/Root/adana-kultur-yolu/subscribe-after.svg');
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}

@media #{$media-md} {
  .subscribe {
    &__form {
      &::before {
        width: 200px;
        height: 196px;
        right: 0;
        left: auto;
        top: -100px;
        background-position: center right;
      }
      &::after {
        width: 227px;
        height: 170px;
        position: absolute;
        bottom: -100px;
        left: 0;
        z-index: -1;
        background-position: center bottom;
      }
      &__in {
        padding: 44px 12px 44px 12px;
        &__title {
          font-size: 24px;
          line-height: 37.2px;
        }
        &__desc {
          font-size: 12px;
          line-height: 18.6px;
        }
        &__group {
          button {
            position: relative;
            background-color: $secondary-500;
            top: auto;
            right: auto;
            margin-top: 10px;
            display: block;
            width: 100%;
          }
        }
      }
    }
  }
}

@media #{$media-sm} {
  .subscribe {
    &__form {
      &::before {
        width: 200px;
        height: 196px;
        right: 0;
        left: auto;
        top: -100px;
        background-position: center right;
      }
      &::after {
        width: 227px;
        height: 170px;
        position: absolute;
        bottom: -100px;
        left: 0;
        z-index: -1;
        background-position: center bottom;
      }
      &__in {
        padding: 44px 12px 44px 12px;
        &__title {
          font-size: 24px;
          line-height: 37.2px;
        }
        &__desc {
          font-size: 12px;
          line-height: 18.6px;
        }
        &__group {
          button {
            position: relative;
            background-color: $secondary-500;
            top: auto;
            right: auto;
            margin-top: 10px;
            display: block;
            width: 100%;
          }
        }
      }
    }
  }
}
