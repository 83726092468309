.about-us-categories {
  margin-top: 100px;

  &__scroll-area {
    height: 300vh;
    position: relative;
    overflow-x: initial;
  }

  &__items-wrapper {
    position: sticky;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    overflow: visible;
    align-items: self-start;
    overflow-x: hidden;
  }

  &__items {
    white-space: nowrap;

    * {
      box-sizing: border-box;
    }

    &__item {
      width: 250px;
      height: 334px;
      display: inline-block;
      border-radius: 12px 40px 10px 40px;
      border: 2px solid transparent;
      background-color: white;

      position: relative;
      cursor: pointer;

      transition: all 0.5s ease;

      &-footer {
        height: 84px;
        background-color: white;
        border: 1px solid $grey-300;
        border-top: none;
        border-radius: 0 0 10px 40px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        gap: 2px;

        h2 {
          font-size: 24px;
          line-height: 37px;
          color: $secondary-500;
          font-weight: 700;
          margin: 0;
          transition: all, 0.4s;
        }

        span {
          font-size: 12px;
          line-height: 19px;
          font-weight: 700;
          color: $grey-500;
        }
      }

      &:hover {
        border-color: $secondary-500;
        .about-us-categories__items__item {
          &-footer {
            h2 {
              color: $primary-500;
            }
          }
        }
      }

      &:hover &-footer {
        border-color: transparent;
      }

      figure {
        height: calc(100% - 84px);
        width: 100%;
        overflow: hidden;
      }

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}
