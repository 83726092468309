.similar-event-list {
  &-title {
    margin-bottom: 16px;
    h3 {
      font-size: 42px;
      font-weight: bold;
      line-height: 58px;
      color: #ed3c6b;
    }
  }

  &-events {
    .row {
      row-gap: 20px;
      .col {
        padding: 0 10px;
      }
    }
  }

  &-link {
    margin-top: 40px;
    text-align: center;

    a {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.05);
      padding: 10px 40px;
      border-radius: 4px 20px;
      background-color: #362e60;
      color: #fff;
    }
  }
}

@media #{$media-sm} {
  .similar-event-list {
    &-title {
      h3 {
        font-size: 18px;
        line-height: 27px;
      }
    }

    &-events {
      .row {
        row-gap: 24px;
      }
    }

    &-link {
      a {
        display: flex;
        justify-content: center;
      }
    }
  }
}
