@keyframes deleteSelectedAnim {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(135%);
    opacity: 0;
  }
}

.event-filter {
  &-filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    &.archive-filters {
      justify-content: flex-end;
    }

    .filter-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      .filter-button-layout {
        position: relative;

        .filter-button {
          position: relative;
          display: inline-flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
          padding: 14px 24px;
          border-radius: 20px 20px 20px 8px;
          background-color: #f1f5f9;
          border: none;
          cursor: pointer;

          &-title {
            font-size: 16px;
            font-weight: bold;
            line-height: 24px;
            color: #362e60;

            &.select-title {
              color: $secondary-500;
            }
          }

          .c-icon {
            color: #cbd5e1;
            transition: all 200ms ease-in-out;

            &.active {
              color: #ed3c6b;
              transform: rotate(-90deg);
            }
          }

          &.selected::after {
            content: '';
            position: absolute;
            bottom: 4px;
            left: 4px;
            width: 8px;
            height: 8px;
            border-radius: 100%;
            background-color: #ed3c6b;
          }
        }

        .filter-button-list {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 2;
          padding: 12px 0;
          border-radius: 8px 20px;
          background-color: #fff;
          border: 1px solid #e2e8f0;
          box-shadow: 0 4px 44px 0 rgba(0, 0, 0, 0.04);
          max-height: 300px;
          overflow-y: auto;
          transform: translateY(calc(100%));
          opacity: 0;
          pointer-events: none;
          visibility: hidden;
          transition: all 250ms ease-in-out;

          li {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            column-gap: 8px;
            list-style-type: none;
            text-align: start;
            text-wrap: nowrap;
            padding: 10px 20px 10px 10px;
            margin-top: 0;

            &:not(:last-child) {
              border-bottom: 1px solid #f1f5f9;
            }

            .already-active {
              pointer-events: none;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
            }

            .delete-filter-icon {
              cursor: pointer;
              background-color: $secondary-500;
              color: #fff;
              padding: 2px;
              border-radius: 4px;
            }

            .active-festival {
              font-size: 12px;
              font-weight: bold;
              line-height: 18px;
              color: $secondary-500;
              background-color: $soft-red-dark;
              padding: 4px 10px;
              border-radius: 10px;
            }

            .selected-filter-name {
              color: $secondary-500;
            }

            &.festival-first {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 8px;
              padding: 10px 14px;

              .info-text {
                font-size: 14px;
                line-height: 21px;
                color: #362e60;
                margin-top: 1px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
              }

              .c-icon {
                color: #362e60;
              }
            }

            button {
              all: inherit;
              margin: 0;
              padding: 0;
              border: none;
              cursor: pointer;

              span {
                font-size: 14px;
                line-height: 21px;
                color: #362e60;
              }
            }
          }

          &.visible {
            transform: translateY(calc(100% + 8px));
            opacity: 1;
            pointer-events: auto;
            visibility: visible;
          }
        }
      }
    }

    .filter-search {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      .keyword-search {
        position: relative;

        &.enter-value {
          .c-icon {
            color: $secondary-500;
          }
        }

        &-input {
          padding: 14px 44px 14px 54px;
          border: 1px solid #f1f5f9;
          border-radius: 20px 20px 20px 8px;
          font-size: 16px;
          font-weight: bold;
          line-height: 24px;
          color: $secondary-500;
          width: 130px;
          transition: all 250ms ease-in-out;

          &::placeholder {
            font-size: 16px;
            font-weight: bold;
            line-height: 24px;
            color: #362e60;
          }

          &.focused {
            &::placeholder {
              font-weight: bold;
              color: $grey-500;
            }
          }

          &.mobile-v {
            &:focus {
              outline: none;
              width: 130px;
            }
          }

          &:focus {
            outline: none;
            width: 250px;
          }
        }

        .search-icon {
          position: absolute;
          transform: translateY(-50%);
          top: 50%;
          left: 25px;
        }

        .clear-search-button {
          all: inherit;
          cursor: pointer;
          position: absolute;
          transform: translateY(-50%);
          top: 50%;
          right: 25px;
          opacity: 0;
          pointer-events: none;
          visibility: hidden;

          &.active {
            opacity: 1;
            pointer-events: all;
            visibility: visible;
          }
        }
      }
    }

    .filter-panel {
      position: relative;
      .button {
        background-color: $grey-200;
        color: $primary-500;
        font-size: 16px;
        padding: 13px 24px 13px 24px;
        border-radius: 20px 20px 20px 8px;
        &-text {
          line-height: 24.8px !important;
        }
      }
      &--filtered {
        &::after {
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background-color: $secondary-500;
          position: absolute;
          bottom: 5px;
          left: 5px;
        }
      }
    }
  }

  &-selected {
    position: relative;
    margin-bottom: 24px;
    min-height: 31px;

    .selected-item {
      position: relative;
      z-index: 1;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      padding: 6px 16px;
      border-radius: 100px;
      border: 1px solid #f1f5f9;
      background-color: #fff;

      &:not(:last-child) {
        margin-right: 8px;
      }

      span {
        font-size: 12px;
        font-weight: bold;
        line-height: 17px;
        color: #362e60;
      }

      .delete-item-button {
        all: inherit;
        border: 0;
        padding: 0;
        margin: 0;
        cursor: pointer;
      }

      .c-icon {
        color: #cbd5e1;
      }

      &.deleted-animate {
        animation: deleteSelectedAnim 300ms ease-in-out forwards;
      }
    }
  }
}

@media #{$media-md} {
  .event-filter {
    margin-bottom: 24px;
    &-filters {
      .filter-search {
        width: 100%;
        justify-content: space-between;
        .keyword-search-input {
          width: 100px !important;
        }
      }
    }
  }
}

@media #{$media-sm} {
  .event-filter {
    margin-bottom: 24px;
    &-selected {
      margin: 24px 0 0 0;
      .selected-item {
        margin: 4px;
      }
    }
    &-filters {
      .filter-search {
        width: 100%;
        justify-content: space-between;
        .keyword-search-input {
          width: 128px !important;
          padding: 14px 14px 14px 54px;
          font-size: 14px;
        }
      }
    }
  }
}
