.soon-festivals {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 44px;
  .festival {
    background-color: light-neutral(0);
    border: 1px solid $grey-200;
    border-radius: 10px 30px 10px 30px;
    padding: 22px;
    margin: 0 6px;
    text-align: start;
    min-width: 280px;
    position: relative;
    transition: all, 0.4s ease;
    &__title {
      font-size: 18px;
      color: $primary-500;
      line-height: 28px;
      font-weight: 700;
      display: block;
      transition: all, 0.4s ease;
    }
    &__date {
      color: $grey-500;
      font-size: 16px;
      font-weight: 400;
      height: 29px;
      line-height: 29px;
      display: block;
    }
    &__days-left {
      background-color: $secondary-500;
      color: light-neutral(0);
      display: flex;
      width: max-content;
      line-height: 17px;
      padding: 2px 9px 2px 9px;
      border-radius: 12px;
      box-sizing: border-box;
      font-size: 11px;
      .c-icon {
        margin-left: 10px;
        line-height: 17px !important;
      }
    }
    &__soon-icon {
      position: absolute;
      right: 10px;
      bottom: 10px;
      color: $secondary-500;
    }
    &--active {
      border-color: $secondary-500;
      .festival__title {
        color: $secondary-500;
      }
    }
    &:hover {
      border-color: $secondary-500;
      .festival__title {
        color: $secondary-500;
      }
    }
  }
}

@media #{$media-sm} {
  .soon-festivals {
    .festival {
      min-width: 268px;
      &__title {
        font-size: 14px;
        line-height: 21.7px;
      }
      &__date {
        font-size: 12px;
        line-height: 18.6px;
      }
    }
  }
}
