.categories {
  overflow: hidden;
  position: relative;
  .col {
    width: 20%;
    padding: 10px;
    box-sizing: border-box;
  }
  &__item {
    position: relative;
    border: 2px solid transparent;
    transition: all, 0.4s ease-in;
    border-radius: 12px 40px 10px 40px;
    figure {
      border-radius: 12px 40px 0 0;
      position: relative;
      overflow: hidden;
      .image {
        width: 100%;
        image {
          width: 100%;
        }
      }
    }
    &__head {
      border-width: 0px 1px 1px 1px;
      border-style: solid;
      border-color: $grey-300;
      padding: 13px 0px 13px 0;
      box-sizing: border-box;
      position: relative;
      overflow: hidden;
      border-radius: 0 0 10px 40px;
      text-align: center;
      .title {
        line-height: 37px;
        font-size: 24px;
        font-weight: 700;
        color: $secondary-500;
        transition: all, 0.4s ease-in;
      }
      .count {
        font-size: 12px;
        font-weight: 700;
        color: $grey-500;
        line-height: 18.6px;
        span {
          margin-right: 5px;
        }
      }
      p {
        font-size: 12px;
        color: $primary-500;
        font-weight: 700;
      }
      .c-icon {
        color: $secondary-500;
        transition: all, 0.4s ease-in;
      }
    }
    &--all {
      background-color: $soft-orange;
      height: 100%;
      border-radius: 12px 40px 10px 40px;
      border: none;
      .categories__item__head {
        text-align: left;
        padding: 20px;
        border: none;
        width: 80%;
      }
      &::before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        background-image: url('https://cdn.tga.net.tr/glomil-prod/Root/adana-kultur-yolu/category-before.svg');
        width: 70%;
        height: 129px;
        background-size: 100%;
        background-position: bottom right;
        background-repeat: no-repeat;
      }
      &:hover {
        background-color: $soft-red-dark;
        .categories__item__head {
          .title,
          .c-icon {
            color: $primary-500;
          }
        }
      }
    }
    &:hover {
      border-color: $secondary-500;
      .categories__item {
        &__head {
          .title {
            color: $primary-500;
          }
        }
      }
    }
  }
}
