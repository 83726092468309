.detay-gallery {
  margin-top: 60px;
  margin-bottom: 60px;

  .title {
    display: block;
    width: calc(90% - 30px);
    margin: auto;
    font-size: 42px;
    font-weight: bold;
    line-height: 58px;
    color: #ed3c6b;
  }

  &-slider {
    position: relative;
    margin-top: 32px;

    .swiper {
      padding: 0 5%;
    }

    &-item {
      a {
        display: block;
        outline: none !important;
        img {
          padding: 10px;
          border-radius: 40px 10px;
          aspect-ratio: 1 / 1;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
}

@media #{$media-lg} {
  .detay-gallery {
    .title {
      font-size: 32px;
      line-height: 48px;
    }
  }
}

@media #{$media-md} {
  .detay-gallery {
    &-slider {
      .gallery-prev-arrow,
      .gallery-next-arrow {
        padding: 4px 2px 9px 2px;
      }
    }
  }
}

@media #{$media-sm} {
  .detay-gallery {
    margin-top: 60px;
    margin-bottom: 60px;

    .title {
      font-size: 18px;
      line-height: 27px;
    }

    &-slider {
      margin-top: 16px;
    }
  }
}
