.event-filter-panel {
  position: fixed;
  top: -150%;
  left: 0;
  top: -150%;
  background: $grey-100;
  z-index: 8;
  width: 100%;
  height: 0;
  pointer-events: none;
  transition: all, 0.4s ease-in-out;
  box-sizing: border-box;
  transition: all, 1s;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  padding: 0 0 80px 0;

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .react-datepicker {
    width: 100%;
    &__month-container {
      width: inherit;
    }
    &__week {
      display: flex;
      justify-content: space-evenly;
    }
    &__day {
      &--today {
        background: transparent !important;
      }
      &--seleceted {
        background: $secondary-500 !important;
      }
      &.react-datepicker__day--keyboard-selected {
        color: $primary-500 !important;
      }
    }
    .react-datepicker__day--today.react-datepicker__day--selected {
      background: transparent;
    }
  }

  &__head {
    z-index: 2;
    display: flex;
    justify-content: space-between;
    background-color: light-neutral(0);
    border: 1px solid $grey-200;
    padding: 10px 0;
    width: 100%;
    .button {
      background: transparent !important;
      border-radius: 0;
      padding: 0 18.4px;
      display: block;
      box-shadow: none !important;
      .button-text {
        font-size: 18px;
        font-weight: bold;
        color: $primary-500;
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 48px !important;
        .c-icon {
          margin-right: 12px;
        }
      }
      &--clear {
        margin-right: 18.4px;
        background-color: $soft-red-dark !important;
        border-radius: 20px 4px 20px 4px;
        .button-text {
          color: $secondary-600;
          line-height: 35px !important;
        }
      }
    }
  }

  &__calendar {
    padding: 12px 16px;
    box-sizing: border-box;
    background-color: light-neutral(0);
    margin-bottom: 12px;
    border-bottom: 2px solid $grey-200;
    .filter-calendar {
      border: none;
      padding: 0;
    }
  }

  &__buttons {
    padding: 12px 0;
    border-top: 1px solid $grey-200;
    background: light-neutral(0);
    & > .button {
      background: transparent !important;
      border-radius: 0;
      padding: 0 18.4px;
      display: block;
      width: 100%;
      border-bottom: 1px solid $grey-200;
      position: relative;
      .button-text {
        line-height: 52px !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
        color: $primary-500;
        .c-icon {
          color: $grey-400;
        }
      }
      &.selected {
        &::after {
          content: '';
          width: 8px;
          height: 8px;
          background-color: $secondary-500;
          position: absolute;
          right: 50px;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 100%;
        }
      }
    }
    &__append {
      padding: 14px 16px 0 16px;
      .button {
        width: 100%;
        padding: 0;
        box-sizing: border-box;
        &-text {
          line-height: 48px !important;
        }
      }
    }
  }

  &__selection {
    position: fixed;
    width: 100%;
    height: calc(100% - 50px);
    background: light-neutral(0);
    right: -100%;
    transition: all, 0.5s;
    z-index: 9;
    top: 50px;
    overflow-y: scroll;
    &__options {
      padding-bottom: 80px;
      &__alert {
        padding: 12px 16px;
        display: flex;
        align-items: center;
        .c-icon {
          margin-right: 12px;
        }
      }
      &__option {
        position: relative;
        .button {
          background: transparent !important;
          border-radius: 0;
          padding: 0 18.4px;
          display: block;
          width: 100%;
          border-bottom: 1px solid $grey-200;
          position: relative;
          .button-text {
            line-height: 20px !important;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            font-weight: normal;
            color: $primary-500;
            padding: 15px 0;
            text-align: left;
            .now {
              background-color: $soft-red-dark;
              padding: 4px 10px;
              border-radius: 10px;
              font-weight: bold;
              color: $secondary-500;
              font-size: 11px;
            }
            .c-icon {
              color: $grey-400;
            }
          }
          &.selected {
            .button-text {
              color: $secondary-500;
              padding-right: 40px;
            }
          }
        }
        .clear {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          padding: 0;
          margin: 0;
          width: max-content;
          .button-text {
            width: 35px;
            height: 35px;
            border-radius: 5px;
            background-color: $secondary-500 !important;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            .c-icon {
              color: light-neutral(0);
            }
          }
        }
      }
    }
    &--active {
      right: 0;
    }
  }

  &--active {
    height: calc(100% - 50px);
    top: 50px;
    pointer-events: auto;
  }
}
