.footer {
  background-color: $primary-900;
  border-radius: 60px 60px 0 0;
  padding: 60px 0 200px 0;
  color: light-neutral(0);
  line-height: 22px;
  position: relative;
  .social-media-list {
    a {
      width: 44px;
      height: 44px;
      display: block;
      background-color: $primary-800;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      border: 1px solid $primary-700;
      color: $primary-500;
      transition: all, 0.4s ease;
      &:hover {
        color: light-neutral(0);
      }
    }
  }
  .navigation {
    margin: 24px 0;
    display: flex;
    &__menu {
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        row-gap: 13px;
        font-size: 14px;
        li {
          margin: 0 4px;
          a {
            background-color: $primary-800;
            border: 1px solid $primary-700;
            border-radius: 20px 4px 20px 4px;
            padding: 10px 40px 10px 40px;
            color: light-neutral(0);
            outline: none;
            font-weight: 700;
            transition: all, 0.4s ease;
            display: block;
            &:hover {
              background-color: $secondary-500;
            }
          }
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    &__language {
      background-color: $primary-800;
      border: 1px solid $primary-700;
      border-radius: 20px 4px 20px 4px;
      padding: 10px 40px 10px 40px;
      color: light-neutral(0);
      margin-left: 8px;
      ul {
        display: flex;
        list-style-type: none;
        padding: 0;
        margin: 0;
        font-size: 14px;
        li {
          margin: 0 5px;
          height: 22px;
          display: flex;
          align-items: center;
          button {
            background: transparent !important;
            box-shadow: none !important;
            outline: none !important;
            color: $primary-400;
            text-transform: uppercase;
            font-weight: 700;
            &.current {
              color: light-neutral(0);
            }
          }
        }
      }
    }
  }
  &__copyright {
    display: flex;
    flex-direction: column;
    margin-top: 8px;

    span {
      font-size: 12px;
      font-weight: normal;
      line-height: 18px;
      color: $primary-400;
    }
  }
  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    background-image: url('/images/footer_BG.svg');
    background-position: right bottom;
    background-repeat: no-repeat;
    width: 754px;
    height: 195px;
    filter: grayscale(1);
  }
  .scrollTop {
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: 1;
    outline: none;
    box-shadow: none;
    padding: 24px 10px 24px 10px;
  }

  .policy {
    &-list {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 13px;

      &-item {
        margin: 0;
        list-style-position: inside;
        font-size: 14px;
        font-weight: bold;
        line-height: 21px;

        &:first-child {
          list-style-type: none;
        }

        a {
          transition: all 250ms ease-in-out;
        }

        &:hover {
          a {
            color: $secondary-500;
          }
        }
      }
    }
  }
}

@media #{$media-sm} {
  .footer {
    .navigation {
      flex-direction: column;
      &__menu {
        ul {
          flex-direction: column;
          li {
            a {
              text-align: center;
            }
          }
        }
      }
      &__language {
        margin: 4px 0 0 0;
        &__list {
          justify-content: center;
        }
      }
    }
    &::after {
      content: '';
      width: 100%;
      background-size: 100%;
      background-image: url('/images/footer_BG--mobile.svg');
    }
    .policy {
      margin-top: 14px;
      &-list {
        flex-direction: column;
        gap: 12px;

        &-item {
          list-style-type: none;
        }
      }
    }
    &__copyright {
      align-items: center;
      margin-top: 18px;
    }
  }
}
