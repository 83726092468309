.contact {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 12px;
  margin: 34px 0;
  &__item {
    padding: 22px;
    border-radius: 10px 30px 10px 30px;
    background-color: light-neutral(0);
    border: 1px solid $grey-200;
    text-align: left;
    &__title {
      font-size: 18px;
      line-height: 27.9px;
      font-weight: 700;
      color: $primary-500;
    }
    &__desc {
      font-size: 16px;
      line-height: 28.8px;
      color: $grey-500;
      margin: 0;
    }
  }
}

@media screen and (max-width: 1360px) and (min-width: 1200px) {
  .contact {
    &__item {
      &__title {
        font-size: 14px;
      }
      &__desc {
        font-size: 12px;
      }
    }
  }
}
