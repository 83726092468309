.photo-gallery {
  position: relative;
  margin: 2rem 0;

  &-trigger {
    position: relative;
    width: 100%;
    cursor: pointer;
    outline: none;
    display: block;
    border: 0;
    margin: 0;
    padding: 0;
    background-color: transparent;
    box-sizing: border-box;

    &:hover .photo-gallery-content,
    &:focus .photo-gallery-content {
      opacity: 1;
    }
  }

  &-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    box-sizing: border-box;
    padding: $padding-lg;
    color: #fff;
    transition: 150ms;
    opacity: 0;
    z-index: 10;
    font-size: 15px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &-caption {
    font-size: 1.2em;
    color: #fff;
    display: block;
    font-weight: 500;
    margin: 0 0 4px 0;
  }

  &-subcaption {
    font-size: 1em;
    color: #fff;
    font-weight: 400;
    display: block;
    margin: 0;
  }

  .image {
    width: 100%;
  }

  .image-node {
    object-fit: cover;
  }
}
