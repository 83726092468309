.search {
  box-sizing: border-box;
  position: absolute;
  right: 0;
  width: 176px;
  z-index: 1;
  transition: all, 0.4s ease;

  &__form {
    position: relative;
    width: 100%;
    border: 1px solid $grey-200;
    border-radius: 100px;
    border-top: none;
    border-bottom: none;
    overflow: hidden;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    input {
      line-height: 22px;
      padding: 20px 20px 20px 65px;
      box-sizing: border-box;
      border: none !important;
      box-shadow: none !important;
      outline: none !important;
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      border-radius: 100px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &__button {
      background: transparent !important;
      box-shadow: none !important;
      position: absolute;
      border: none !important;
      padding: 0;

      &--icon {
        color: $secondary-500 !important;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
      }

      &--close {
        right: -50px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        pointer-events: none;
      }

      .button-text {
        color: $secondary-500 !important;
        border-radius: 5px;
        padding: 7.5px;
        box-sizing: border-box;
        border-radius: 100%;
        box-sizing: border-box;
        display: flex;
        width: 37px;
        height: 37px;
        align-items: center;
        justify-content: center;
        margin: 0;

        span {
          margin: 0;
          position: relative;

          &.search-button {
            top: -2px;
          }

          &.close {
            top: -1px;
          }
        }

        &:hover {
          background-color: $secondary-500;
          color: light-neutral(0) !important;
        }
      }
    }
  }

  &--active {
    width: 100%;

    .search__form {
      &__button {
        &--close {
          right: 16px;
          opacity: 1;
          pointer-events: auto;
          transition-delay: 0.4s;
        }
      }
    }
  }
}

@media screen and (max-width: 1360px) and (min-width: 1200px) {
  .search__form {
    input {
      font-size: 12px;
    }
  }
}
