.detay-sidebar {
  position: sticky;
  top: 99px;
  left: 0;
  background-color: light-neutral(0);
  border-radius: 40px 10px 40px 10px;
  border: 1px solid #e2e8f0;

  &-dates {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 24px;
    padding: 24px;

    .item {
      span {
        font-size: 24px;
        font-weight: 800;
        color: #362e60;
      }

      p {
        color: #94a3b8;
        font-size: 12px;
        margin: 4px 0 0 0;
      }

      &:not(:last-child) {
        border-right: 1px solid #e2e8f0;
        padding-right: 20px;
      }

      &:not(:first-child) {
        padding-left: 20px;
      }
    }
  }

  &-sortdesc {
    padding: 24px 24px 20px 24px;
    border-top: 1px solid #e2e8f0;

    &.border-top-false {
      border-top: none;
    }

    .labels {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
      margin-block-start: 0;
      margin-block-end: 0;

      &-item {
        list-style-position: inside;
        margin: 0;

        span {
          padding: 4px 10px;
          border: 1px solid #f1f5f9;
          font-size: 12px;
          line-height: 18.6px;
          border-radius: 10px;
          font-weight: bold;
        }

        &:first-child {
          list-style-type: none;
        }

        &.pain {
          span {
            background-color: #f8edf4;
            color: #ed3c6b;
          }
        }

        &.free {
          span {
            background-color: #eff7ec;
            color: #78af64;
          }
        }
      }
    }

    .content {
      margin-top: 10px;

      &-title {
        color: #ed3c6b;
        font-size: 24px;
        line-height: 37.2px;
        font-weight: bold;
      }

      &-desc {
        margin: 4px 0 0 0;
        font-size: 14px;
        line-height: 21.7px;
        color: #475569;
      }

      &-link {
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        column-gap: 10px;
        margin-top: 12px;
        margin-bottom: 8px;
        font-size: 12px;
        padding: 2px 9px;
        color: #fff;
        background-color: #362e60;
        border-radius: 12px;
      }
    }
  }

  &-sessions {
    padding: 24px;
    border-top: 1px solid #f1f5f9;

    .title {
      color: #ed3c6b;
      font-size: 24px;
      line-height: 24px;
      font-weight: bold;
    }

    .list {
      margin-top: 14px;

      li {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0 16px 0;
        margin: 0;

        &:not(:last-child) {
          border-bottom: 1px solid #f1f5f9;
        }

        .date {
          font-size: 16px;
          font-weight: bold;
          line-height: 24px;
          color: #362e60;
        }

        .time {
          font-size: 16px;
          font-weight: bold;
          line-height: 24px;
          color: #94a3b8;
        }
      }
    }
  }

  &-sales {
    padding: 24px;
    border-top: 1px solid #f1f5f9;
    .title {
      font-size: 24px;
      line-height: 24px;
      font-weight: bold;
      color: #ed3c6b;
    }

    .sales-points {
      list-style-type: none;
      li {
        border-bottom: 1px solid #e2e8f0;
        padding-bottom: 8px;

        .sales-points-title {
          display: block;
          font-size: 16px;
          font-weight: bold;
          line-height: 24px;
          color: #362e60;
        }

        .sales-points-desc {
          font-size: 12px;
          line-height: 18px;
          margin: 0;
          color: #94a3b8;
          margin-top: 4px;
        }
      }
    }

    .sales-link {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin-top: 24px;
      border-radius: 4px 20px 4px 20px;
      padding: 10px 40px;
      background-color: #362e60;
      color: #fff;
    }
  }
}

@media #{$media-xl} {
  .detay-sidebar {
    &-dates {
      padding: 18px;
      .item {
        span {
          font-size: 22px;
        }
      }
    }

    &-sortdesc {
      padding: 20px 20px 16px 20px;

      .labels {
        gap: 8px;

        &-item {
          span {
            padding: 2px 8px 6px 8px;
          }
        }
      }

      .content {
        margin-top: 18px;

        &-title {
          font-size: 22px;
        }
      }
    }

    &-sessions {
      padding: 20px;

      .title {
        font-size: 22px;
      }

      .list {
        margin-top: 8px;

        li {
          padding: 10px 0 14px 0;
        }
      }
    }

    &-sales {
      padding: 20px;
      .title {
        font-size: 22px;
      }

      .sales-points {
        margin-top: 16px;
        li {
          padding-bottom: 10px;
        }
      }

      .sales-link {
        margin-top: 14px;
        padding: 8px 40px;
      }
    }
  }
}

@media #{$media-md} {
  .detay-sidebar {
    &-dates {
      .item {
        span {
          font-size: 16px;
        }

        &:not(:last-child) {
          padding-right: 10px;
        }

        &:not(:first-child) {
          padding-left: 10px;
        }
      }
    }

    &-sortdesc {
      .content {
        margin-top: 12px;

        &-title {
          font-size: 18px;
        }
      }
    }

    &-sessions {
      padding: 14px 20px;

      .title {
        font-size: 18px;
      }

      .list {
        margin-top: 8px;

        li {
          padding: 10px 0 10px 0;
          .date {
            font-size: 14px;
            line-height: 22px;
          }

          .time {
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }

    &-sales {
      padding: 12px 20px;
      .title {
        font-size: 18px;
      }

      .sales-points {
        margin-top: 10px;
        li {
          .sales-points-desc {
            margin-top: 2px;
          }
        }
      }

      .sales-link {
        margin-top: 8px;
        padding: 6px 10px;
      }
    }
  }
}

@media #{$media-sm} {
  .detay-sidebar {
    position: relative;
    top: 0;
    margin-top: 16px;
    margin-bottom: 24px;
    &-dates {
      .item {
        span {
          font-size: 18px;
          line-height: 27px;
        }

        p {
          font-size: 12px;
          font-weight: bold;
          margin: 0;
        }
      }
    }

    &-sortdesc {
      padding: 22px 16px 20px 16px;
      .content {
        margin-top: 18px;
        &-title {
          font-size: 18px;
          line-height: 27.2px;
        }
      }
    }

    &-sessions {
      padding: 16px;
      .title {
        font-size: 18px;
        line-height: 27px;
      }
    }

    &-sales {
      padding: 16px;
      .title {
        font-size: 18px;
        line-height: 27px;
      }

      .sales-link {
        box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.05);
      }
    }
  }
}
