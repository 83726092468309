.detay-sidebar-global-events {
  .detay-box {
    display: flex;
    flex-direction: column;
    border: 1px solid #e2e8f0;
    border-radius: 40px 10px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 26px 24px;
      border-bottom: 1px solid #e2e8f0;

      .festival-and-date {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .festival-name {
          font-size: 16px;
          font-weight: bold;
          color: #ed3c6b;

          &::before {
            content: '•';
            display: inline-block;
            text-align: center;
            min-width: 22px;
            color: var(--DN0);
            padding-bottom: 2px;
          }
        }
        .date {
          font-size: 24px;
          font-weight: bold;
          color: #362e60;
        }
      }

      .buy-ticket {
        .sales-link {
          text-wrap: nowrap;
          border-radius: 2px 10px;
          padding: 12px 27px;
          background-color: #362e60;
          color: #fff;
        }
      }
    }

    &-body {
      padding: 26px 24px 18px 24px;

      .labels {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 12px;
        margin-block-start: 0;
        margin-block-end: 0;

        &-item {
          list-style-position: inside;
          margin: 0;

          span {
            padding: 4px 10px;
            border: 1px solid #f1f5f9;
            font-size: 12px;
            line-height: 18.6px;
            border-radius: 10px;
            font-weight: bold;
          }

          &:first-child {
            list-style-type: none;
          }

          &.pain {
            span {
              background-color: #f8edf4;
              color: #ed3c6b;
            }
          }

          &.free {
            span {
              background-color: #eff7ec;
              color: #78af64;
            }
          }
        }
      }

      .content {
        margin-top: 18px;

        &-title {
          display: block;
          color: #ed3c6b;
          font-size: 24px;
          line-height: 28px;
          font-weight: bold;
          margin-bottom: 4px;
        }

        &-place {
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 6px;
          font-size: 14px;
          font-weight: medium;
          color: #475569;

          .c-icon {
            font-size: 16px;
            padding: 8px;
            border-radius: 8px;
            background-color: #f8fafc;
          }
        }
      }
    }

    &-footer {
      border-radius: 0px 0px 40px 10px;
      overflow: hidden;

      .map-layout {
        position: relative;
        width: 100%;
        height: 170px;

        .go-map-route {
          position: absolute;
          left: 23.5px;
          bottom: 19.44px;
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 6px;
          padding: 4px 10px;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0.2px;
          line-height: 17px;
          color: var(--LN0);
          border: 1px solid #f1f5f9;
          border-radius: 13px;
          background-color: #362e60;

          .c-icon {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media #{$media-md} {
  .detay-sidebar-global-events {
    .detay-box {
      &:last-child {
        margin-bottom: 20px;
      }

      &-header {
        flex-direction: column;
        align-items: center;
        row-gap: 12px;
        padding: 20px 16px;

        .festival-and-date {
          row-gap: 6px;
          .festival-name {
            &::before {
              display: none;
            }
          }
          .date {
            margin: auto;
            font-size: 20px;
          }
        }

        .buy-ticket {
          .sales-link {
            display: inline-block;
            padding: 8px 42px;
          }
        }
      }

      &-body {
        padding: 20px 16px;

        .labels {
          flex-direction: column;

          &-item {
            list-style-type: none;
          }
        }

        .buy-ticket {
          margin: 16px 0 6px 0;
          text-align: center;
          .sales-link {
            display: inline-block;
            padding: 8px 42px;
            border-radius: 2px 10px;
            background-color: #362e60;
            color: #fff;
          }
        }

        .content {
          margin-top: 18px;

          &-title {
            font-size: 20px;
            text-align: center;
          }
        }
      }

      &-footer {
        .map-layout {
          height: 190px;

          .go-map-route {
            font-weight: 400;
          }
        }
      }
    }
  }
}
