body {
  &.open-custom-modal {
    overflow: hidden !important;
  }
}

.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;

  &-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
  }

  &-in {
    position: fixed;
    top: 50%;
    left: 50%;
    width: fit-content;
    height: fit-content;
    transform: translate(-50%, -50%);
    background-color: transparent;
    z-index: 1010;
    max-width: 600px;

    .banner-layout {
      position: relative;
    }
  }

  .custom-modal-close-svg {
    position: absolute;
    width: 28px;
    height: 28px;
    right: calc(32px * 1.5);
    top: calc(32px * 1.5);
    cursor: pointer;
    fill: rgba(255, 255, 255, 0.7);
    transition: all 150ms ease-in-out;

    &:hover {
      fill: rgba(255, 255, 255, 1);
    }
  }
}

@media #{$media-xs} {
  .custom-modal {
    .custom-modal-close-svg {
      width: 24px;
      height: 24px;
      right: 32px;
      top: 32px;
    }
  }
}
