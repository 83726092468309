.infinite-scroll-component__outerdiv {
  .infinite-skeleton {
    margin: 20px 0;

    .col {
      padding: 0 10px;
    }
  }

  .infinite-scroll-component {
    overflow: hidden !important;
  }
}
