.section {
  width: 100%;
  margin: 80px 0;
  z-index: 1;
  position: relative;

  &-header {
    width: 100%;
    &-title {
      display: block;
      margin: 18px 0 !important;
      font-size: 72px;
      line-height: 85px;
      color: $primary-500;
    }

    &-description {
      max-width: 800px;
      font-weight: 500;
      margin: 1em 0 0 0;
    }

    a {
      border-radius: 4px 20px 4px 20px;
      padding: 10px 40px 10px 40px;
      background-color: $primary-500;
      box-shadow: 0px 0px 0px 5px #0000000d;
      color: light-neutral(0) !important;
      font-size: 14px;
      line-height: 21.7px;
      width: max-content;
      display: block;
      transition: all, 0.4s ease-in-out;
      .c-icon {
        margin-left: 10px;
      }
      &:hover {
        background-color: $secondary-500 !important;
      }
    }
  }
}

@media #{$media-lg} {
  .section {
    margin: 40px 0;
    &-header {
      &-title {
        font-size: 50px;
        line-height: 67.2px;
      }
    }
  }
}

@media #{$media-sm} {
  .section {
    margin: 25px 0;
    &-header {
      &-title {
        font-size: 24px;
        line-height: 37.2px;
        text-align: center;
      }
      a {
        margin: 0 auto;
      }
    }
  }
}
