.event-list {
  margin: 44px 0;

  &-events {
    .row.listed {
      row-gap: 20px;
      padding: 10px 4px;
      .col {
        padding: 0 10px;
      }
    }

    .no-data {
      width: 100%;
      &-text {
        display: block;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        border-radius: 12px;
        padding: 17px 14px;
        color: #ed3c6b;
        background-color: #f8edf4;
      }

      &-other {
        display: block;
        font-size: 42px;
        font-weight: bold;
        line-height: 58px;
        color: #ed3c6b;
        margin-bottom: 16px;
      }
    }

    .other-events-buttonv2 {
      margin: 32px 0 22px 0;
      text-align: center;

      a {
        padding: 10px 40px;
        border-radius: 4px 20px;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        gap: 13px;
        background-color: #362e60;
        box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.05);
        color: #fff;

        span {
          font-size: 14px;
          font-weight: bold;
          align-items: center;
        }
      }

      button {
        padding: 10px 40px 10px 40px;
      }
    }
  }
}

@media #{$media-md} {
  .event-list {
    &-events {
      .no-data {
        &-text {
          font-size: 16px;
        }
      }
    }
  }
}

@media #{$media-sm} {
  .event-list {
    &-events {
      .no-data {
        &-text {
          font-size: 14px;
        }
      }
    }
  }
}
