.panel__search {
  position: relative;
  &__form {
    position: relative;
    z-index: 2;
    input {
      line-height: 52px;
      padding: 0 36px 0 55px;
      box-sizing: border-box;
      border: none !important;
      box-shadow: none !important;
      outline: none !important;
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      background-color: $grey-200;
      &::placeholder {
        color: $grey-600;
        opacity: 1; /* Firefox */
      }

      &::-ms-input-placeholder { /* Edge 12 -18 */
        color: $grey-600;
      }
    }
    &__button {
      background: transparent !important;
      box-shadow: none !important;
      position: absolute;
      border: none !important;
      padding: 0;
      &--icon {
        color: $secondary-500 !important;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
      }
      &--close {
        right: -50px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        pointer-events: none;
        transition-delay: 0.4s;
      }
      .button-text {
        color: $secondary-500 !important;
        border-radius: 5px;
        &:hover {
          background-color: $secondary-500;
          color: light-neutral(0) !important;
        }
      }
    }
  }

  .search-result {
    z-index: 1;
    max-height: 0;
    box-sizing: border-box;
    opacity: 1;
    top: -50px;
    overflow: hidden;
    &--active {
      top: 52px;
      height: calc(100vh - 52px);
      max-height: calc(100vh - 52px);
    }
  }

  &--active {
    width: 100%;
    .panel__search__form {
      &__button {
        &--close {
          right: 16px;
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }
}
