.about-path {
  display: flex;
  flex-direction: column;
  gap: 150px;
  position: relative;
  overflow-x: visible;
  margin-top: 150px;

  &__festival-box {
    position: absolute;
    offset-rotate: 0deg;

    width: 80px;
    height: 80px;
    background-color: white;
    padding: 10px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $grey-200;
    z-index: 10;

    .logo {
      overflow: hidden;
      width: 100%;
      height: 100%;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__card {
      position: absolute;
      z-index: 2;
      display: flex;
      flex-direction: column;
      gap: 10px;

      transform: translateY(calc(-50% + 30px)) translateX(calc(50% + 70px));

      &.left {
        transform: translateY(calc(-50% + 30px)) translateX(calc(-50% - 70px));
      }

      &__header {
        h3,
        h4 {
          margin: 0;
          padding: 0;
          font-size: 1rem;
          color: white;
          padding: 8px 16px 8px 16px;
          font-size: 12px;
        }

        h4 {
          background-color: $primary-500;
          white-space: nowrap;
          display: inline-block;
          border-radius: 4px 20px 0 0;
        }

        &__title {
          margin: 0;
          display: flex;
          align-items: center;
          gap: 10px;

          h3 {
            background-color: $secondary-500;
            border-radius: 0 0 20px 4px;
          }

          .date {
            display: block;
            flex: 1;
            font-size: 10px;
            line-height: 12px;
            color: $grey-500;
            white-space: nowrap;
          }
        }
      }

      &__body {
        height: 110px;
        display: flex;
        gap: 10px;

        div {
          background-color: $grey-300;
          flex: 1;
        }
      }

      &__footer {
        color: $grey-600;
      }
    }
  }

  &__watermark {
    height: calc(var(--app-height) - 100px);
    position: sticky;
    top: 100px;
    left: 0;
    width: 100%;
    z-index: 1;

    &__wing {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      bottom: 0;
      resize: both;

      @media ($media-sm) {
        width: 100px;
      }

      &:nth-of-type(1) {
        left: 0;
      }

      &:nth-of-type(2) {
        right: 0;
      }
    }

    &__svg-line {
      position: absolute;
      top: -90px;
      height: calc(var(--app-height));
      width: 2px;
      left: calc(50% - 125px);
      transform: translateX(-50%);
      z-index: 2;

      svg {
        position: absolute;
      }
    }
  }

  &__footer {
    height: 50vh;
  }
}

@media (max-width: 980px) {
  .about-path {
    &__footer {
      height: 75vh;
    }

    &__festival-box {
      &__card {
        transform: translateY(calc(50% + 70px)) translateX(-40px);

        &.left {
          transform: translateY(calc(50% + 70px)) translateX(-40px);
        }

        // last-child
        &.last {
          transform: translateY(calc(50% + 70px)) translateX(0);
        }
      }
    }
  }
}
