.announcement-detail {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 40%
  );

  &-title {
    display: block;
    margin-top: 44px !important;
  }

  &-image {
    border-radius: 40px 10px;
    overflow: hidden;
    height: 738px;
    margin-bottom: 24px;

    .image {
      width: 100%;
      height: 100%;

      img {
        object-fit: contain;
        object-position: center;
      }
    }
  }
}

@media #{$media-sm} {
  .announcement-detail {
    &-title {
      margin-top: 34px !important;
      line-height: 42px !important;
    }

    &-image {
      height: 320px;
      margin-bottom: 12px;
    }
  }
}
