.arrowBtn {
  all: inherit;
  position: absolute;
  cursor: pointer;
  background-color: $primary-500;
  color: white;
  padding: 0;
  z-index: 1;
  transition: all, 0.4s;
  top: 50%;
  transform: translateY(-50%);
  height: 70px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  &--prev {
    left: 0;
    border-radius: 0px 20px 20px 0px;
  }
  &--next {
    right: 0;
    border-radius: 20px 0px 0px 20px;
  }
  &:hover {
    background-color: $secondary-500;
  }
}
