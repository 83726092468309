@mixin theme($mode) {
  [data-theme='#{$mode}'] {
    @content;
  }

  @media (prefers-color-scheme: $mode) {
    [data-theme='auto'] {
      @content;
    }
  }
}

@mixin media-xs() {
  @media screen and (min-width: $breakpoints-xs) {
    @content;
  }
}

@mixin media-sm {
  @media screen and (min-width: $breakpoints-sm) {
    @content;
  }
}

@mixin media-md {
  @media screen and (min-width: $breakpoints-md) {
    @content;
  }
}

@mixin media-lg {
  @media screen and (min-width: $breakpoints-lg) {
    @content;
  }
}

@mixin media-xl {
  @media screen and (min-width: $breakpoints-xl) {
    @content;
  }
}

@mixin pseudo($content: '') {
  content: $content;
  display: block;
  position: absolute;
}

@mixin truncate($boundary) {
  max-width: $boundary;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
