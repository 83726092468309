.festival-calendar {
  margin-top: 25px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;

  @media #{$media-sm} {
    gap: 5px;
  }

  &-item {
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    position: relative;
    overflow: hidden;
    background-color: #061731;
    color: light-neutral(0);
    min-width: 200px;

    @media #{$media-lg} {
      min-width: 100px;
    }

    @media #{$media-md} {
      min-width: 100px;
    }

    @media #{$media-sm} {
      min-width: 100px;
    }

    &-title {
      line-height: 45px;
      font-weight: 700;
      font-size: 20px;
      border-bottom-right-radius: 20px;
      @media #{$media-sm} {
        line-height: 35px;
        font-size: 15px;
      }
    }
    &-date {
      line-height: 35px;
      padding: 0 15px;
      font-size: 13px;
      font-weight: 500;
      @media #{$media-sm} {
        line-height: 25px;
        padding: 0 5px;
        font-size: 8px;
      }
    }
  }

  @media #{$media-lg} {
    grid-template-columns: repeat(4, 1fr);
  }

  @media #{$media-md} {
    grid-template-columns: repeat(4, 1fr);
  }

  @media #{$media-sm} {
    grid-template-columns: repeat(3, 1fr);
  }
}