.festival-location {
  width: fit-content;
  &__city {
    border-radius: 0px 0% 20px 4px;
    padding: 8px 16px 8px 16px;
    font-size: 16px;
    line-height: 25px;
    color: light-neutral(0);
    font-weight: 700;
  }
  &__date {
    font-size: 11px;
    color: $grey-500;
    line-height: 17px;
    margin-left: 6px;
  }
  &__festival {
    border-radius: 4px 20px 4px 0px;
    padding: 8px 16px 8px 16px;
    background-color: $primary-500;
    color: light-neutral(0);
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
  }
}
