.spreat {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px 0 40px 0;
  overflow: hidden;
  .animation-spreat__right,
  .animation-spreat__left {
    svg {
      overflow: visible;
      path {
        transition: all, 0.4s ease-in-out;
      }
    }
  }
}

@media screen and (min-width: 640px) and (max-width: 860px) {
  .spreat {
    margin: 4 0px 0;
    .road {
      width: 150px;
      height: 290px;
    }
  }
}

@media #{$media-sm} {
  .spreat {
    margin: 20px 0;
    .road {
      width: 50px;
      height: 150px;
    }
  }
}
