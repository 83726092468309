.festival-card {
  border: 2px solid transparent;
  transition: all, 0.4s ease;
  border-radius: 10px 40px 10px 40px;
  figure {
    border-radius: 10px 40px 0px 0px;
    position: relative;
    overflow: hidden;
    .image {
      width: 100%;
      height: 100%;
    }
  }
  &__detail {
    background-color: $soft-purple;
    padding: 16px;
    box-sizing: border-box;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: $grey-300;
    border-radius: 0px 0px 10px 40px;
    transition: all, 0.4s ease;
    min-height: 220px;

    &__date {
      margin: 16px 0;
      font-size: 12px;
      color: $primary-400;
      font-weight: bold;
    }
    &__badge {
      border-radius: 4px 12px 4px 12px;
      padding: 6px 16px 6px 16px;
      font-size: 12px;
      line-height: 19px;
      font-weight: 700;
      color: $primary-500;
      background-color: $grey-200;
      width: max-content;
      display: block;
      &--active {
        background-color: $secondary-100;
        color: $secondary-500;
      }
      &--future {
        background-color: $soft-green;
        color: #78af64;
      }
    }
  }
  &:hover {
    border-color: $secondary-400;
    .festival-card__detail {
      background-color: light-neutral(0);
    }
  }
}
