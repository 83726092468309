.announcement {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 15%,
    rgba(255, 255, 255, 1) 95%,
    rgba(255, 255, 255, 0) 100%
  );

  padding: 34px 0;

  .announcement-list {
    margin: 74px 0;

    .row {
      row-gap: 60px;
    }

    &-empty {
      padding: 10px;
      border-radius: 12px;
      background-color: $soft-red;
      border: 1px solid $soft-red-dark;
      text-align: center;

      span {
        font-size: 18px;
        line-height: 27px;
        font-weight: bold;
        color: $secondary-400;
      }
    }
  }

  .pag-item {
    background-color: $primary-500;
    color: white;
    transition: all 150ms ease-in-out;

    &:hover {
      background-color: $primary-500;
    }

    &.active-pagination {
      background-color: $secondary-500;
      color: white;
      cursor: auto;
    }
  }
}

@media #{$media-sm} {
  .announcement {
    .announcement-list {
      margin: 24px 0;

      .row {
        .col {
          padding: 0;
        }
      }
    }
  }
}
