.about-us-hero {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  min-height: calc(var(--app-height) - 150px);
  justify-content: center;

  * {
    font-weight: 400;
    line-height: 115px;
    font-size: 75px;
  }

  &-brackets {
    display: grid;
    grid-template-columns: 2.15fr 1fr 2.15fr;
    max-width: 720px;
    gap: 24px;
    align-items: end;

    padding: 0 10px;

    div {
      display: flex;

      svg {
        width: 100%;
        height: auto;
      }
    }
  }

  h1 {
    margin: 0;
    max-width: 1320px;
    text-align: center;
    color: $primary-500;
    padding-left: 10px;
    padding-right: 10px;

    strong {
      color: $secondary-500;
    }
  }

  @media ($media-md) {
    * {
      font-weight: bold;
      line-height: 58px;
      font-size: 40px;
    }
  }
}

.about-us-hero-wrapper {
  margin-top: 100px;
}
