.quests {
  margin-top: 55px;
  .title {
    font-size: 42px;
    font-weight: bold;
    line-height: 58px;
    color: #ed3c6b;
    margin: 0;
  }

  .row {
    margin-top: 8px;
    row-gap: 8px;
  }

  .quest {
    padding-top: 28px;
    padding-bottom: 28px;
    border-bottom: 1px solid #f1f5f9;

    &-name {
      font-size: 18px;
      font-weight: bold;
      line-height: 27px;
      color: #362e60;
    }

    &-desc {
      margin: 8px 0 0 0;
      font-size: 12px;
      line-height: 18px;
      color: #94a3b8;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      line-clamp: 5;
      -webkit-box-orient: vertical;
    }

    &-date {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      list-style-position: inside;
      gap: 10px;
      padding: 8px 0;
      margin: 8px 0 0 0;

      li {
        margin: 0;

        span {
          padding: 4px 10px;
          border: 1px solid #f1f5f9;
          border-radius: 10px;
          font-size: 12px;
          line-height: 18px;
          font-weight: bold;
          color: #362e60;
        }

        &:first-child {
          list-style-type: none;
        }
      }
    }
  }
}

@media #{$media-lg} {
  .quests {
    margin-top: 35px;
    .title {
      font-size: 32px;
      line-height: 48px;
      margin-bottom: 22px;
    }

    .row {
      margin-top: 0;
      row-gap: 0;
    }

    .quest {
      padding-top: 8px;
      padding-bottom: 22px;
      border-bottom: 1px solid #f1f5f9;

      &-name {
        font-size: 18px;
        font-weight: bold;
        line-height: 27px;
        color: #362e60;
      }

      &-desc {
        margin: 8px 0 0 0;
        font-size: 12px;
        line-height: 18px;
        color: #94a3b8;
      }

      &-date {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        list-style-position: inside;
        gap: 20px;
        padding: 8px 0;
        margin: 8px 0 0 0;

        li {
          margin: 0;
          list-style-type: none;

          span {
            padding: 4px 10px;
            border: 1px solid #f1f5f9;
            border-radius: 10px;
            font-size: 12px;
            line-height: 18px;
            font-weight: bold;
            color: #362e60;
          }

          &:first-child {
            list-style-type: none;
          }
        }
      }
    }
  }
}

@media #{$media-md} {
  .quests {
    .quest {
      &-desc {
        max-width: 80%;
      }
    }
  }
}

@media #{$media-sm} {
  .quests {
    .title {
      font-size: 18px;
      line-height: 28px;
    }

    .quest {
      &:last-child {
        border: none;
      }
      &-desc {
        max-width: 100%;
        margin-top: 4px;
      }
      &-name {
        font-size: 14px;
        line-height: 21px;
      }

      &-date {
        display: inline-flex;
        flex-direction: row;
        align-items: center;

        list-style: {
          list-style-type: disc;
        }
      }
    }
  }
}
