.search-result {
  position: absolute;
  left: 0;
  top: 30px;
  width: 100%;
  max-height: 0;
  background-color: #fff;
  padding: 16px;
  box-sizing: border-box;
  border: 1px solid $grey-200;
  box-shadow: 0px 4px 44px 0px #0000000a;
  border-radius: 20px;
  opacity: 0;
  pointer-events: none;
  transition: all, 0.4s ease;
  &__festivals {
    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      figure {
        width: 54px;
        height: 54px;
        border-radius: 5px 10px 5px 10px;
        overflow: hidden;
        background-color: $grey-200;
        .image {
          width: 100%;
          height: 100%;
        }
      }
      .detail {
        margin-left: 12px;
        &__title {
          font-weight: 700;
          color: $primary-700;
        }
        &__date {
          color: $grey-500;
          font-size: 12px;
          line-height: 19px;
        }
      }
    }
    &__more {
      display: block;
      color: $secondary-500;
      font-weight: 700;
      font-size: 12px;
    }
  }
  &__events {
    hr {
      margin: 16px 0;
      background-color: $grey-200;
    }
    &__item {
      margin-bottom: 16px;
      font-size: 14px;
      color: $primary-500;
      display: block;
      font-weight: 700;
      &:last-child {
        margin: 0;
      }
    }
  }
  &__error {
    color: $grey-500;
    font-size: 14px;
  }
  .spinner {
    margin: 16px auto;
    display: block;
    width: max-content;
  }
  &--active {
    top: 80px;
    opacity: 1;
    pointer-events: auto;
    max-height: 1000px;
  }
}
