.animation-spreat {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 60px 0 40px 0;

  &.mb-clear {
    margin: 60px 0 10px 0;
  }

  figure {
    margin: 0 24px;
    width: 128px;
  }
  .animation-spreat__left,
  .animation-spreat__right {
    margin: 0 12px;
  }
  svg {
    height: 72px;
    path {
      transition: all, 3s ease-in-out;
    }
  }
}

@media #{$media-md} {
  .animation-spreat {
    &__left,
    &__right {
      svg {
        width: 250px;
      }
    }
  }
}

@media #{$media-sm} {
  .animation-spreat {
    margin: 32px 0;
    figure {
      width: 60px;
      margin: 0;
    }
    &__left {
      margin-left: 0 !important;
    }
    &__right {
      margin-right: 0 !important;
    }
    &__left,
    &__right {
      height: 40px;
      svg {
        width: 145px;
        height: 40px;
      }
    }
  }
}
