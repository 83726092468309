.festival__banner {
  padding-top: 130px;
  padding-bottom: 30px;
  background-size: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  &__detail {
    margin: 75px 0 60px 0;
    &__image {
      position: relative;
      overflow: hidden;
      border-radius: 10px 40px 10px 40px;
    }
    &__title {
      font-size: 84px;
      line-height: 109px;
      color: $primary-500;
      font-weight: 700;
      span {
        display: block;
        color: $secondary-500;
      }
    }
    &__date {
      width: max-content;
      margin-top: 12px;
      .c-icon {
        border-radius: 8px;
        padding: 10px;
        background-color: $grey-100;
        color: $secondary-500;
        box-sizing: border-box;
      }
      border-radius: 10px 4px;
      padding: 8px 24px 8px 15px;
      gap: 12px;
      border: 1px solid $grey-300;
      background: light-neutral(0);
      display: flex;
      align-items: center;
      font-size: 24px;
      line-height: 37.2px;
      font-weight: 700;
      color: $primary-500;
    }
    &__description {
      width: 70%;
      margin: 28px 0;
      font-size: 16px;
      line-height: 28.8px;
      color: $grey-600;
    }
  }
}

@media #{$media-lg} {
  .festival__banner {
    &__detail {
      margin: 30px 0;
      &__title {
        font-size: 55px;
        line-height: 75px;
      }
      &__date {
        font-size: 18px;
      }
      &__description {
        font-size: 14px;
      }
    }
  }
}

@media #{$media-sm} {
  .festival__banner {
    padding-top: 30px;
    &__detail {
      &__title {
        font-size: 32px;
        line-height: 42.88px;
      }
      &__date {
        font-size: 14px;
        line-height: 21.7px;
        padding: 4px 9px 4px 6px;
      }
      &__description {
        font-size: 14px;
        width: 100%;
        line-height: 21.7px;
      }
    }
  }
}
