.panel {
  position: fixed;
  top: -150%;
  left: 0;
  background: light-neutral(0);
  z-index: 8;
  width: 100%;
  height: 100vh;
  padding-top: 62.59px;
  pointer-events: none;
  transition: all, 0.4s ease-in-out;
  box-sizing: border-box;
  &__menu {
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        margin: 0;
        a {
          display: flex;
          font-size: 18px;
          font-weight: bold;
          color: $primary-500;
          padding: 12px 16px;
          border-bottom: 1px solid $grey-200;
          align-items: center;
          justify-content: space-between;
          outline: none !important;
          .c-icon {
            color: $grey-400;
          }
        }
      }
    }
  }

  &__language {
    &__list {
      display: flex;
      list-style-type: none;
      padding: 0;
      margin: 0;
      justify-content: space-between;
      li {
        flex-basis: 50%;
        margin: 0;
        button {
          background: transparent !important;
          box-shadow: none !important;
          padding: 10px;
          width: 100%;
          margin: 0;
          text-transform: uppercase;
          color: $primary-500;
          font-weight: 700;
          line-height: 28px;
          display: block;
          font-size: 18px;
          transition: all, 0.4s ease;
          border: 1px solid $grey-200;
          border-radius: 0;
          &.current {
            color: $secondary-500;
          }
        }
        &:first-child {
          button {
            border-width: 0px 1px 1px 0px;
          }
        }
        &:last-child {
          button {
            border-width: 0px 0px 1px 0px;
          }
        }
      }
    }
  }

  &--active {
    top: 0;
    pointer-events: auto;
  }
}
