.header {
  position: fixed;
  width: 100%;
  top: 30px;
  left: 0;
  padding: 16px 0;
  z-index: 9;
  transition: all, 0.4s ease-in-out;
  border-bottom: 1px solid transparent;

  &--scrolled {
    top: 0;
    border-color: $grey-200;
    background-color: light-neutral(0);
  }

  .logo {
    width: 410px;
    a {
      display: block;
      outline: none;
      .image {
        outline: none !important;
      }
    }
  }

  .navigation {
    border: 1px solid $grey-200;
    background-color: light-neutral(0);
    border-radius: 100px;
    display: flex;
    align-items: center;

    &__menu {
      position: relative;
      display: flex;
      padding-right: 176px;
    }

    ul {
      list-style-type: none;
      padding: 20px 35px;
      margin: 0;
      display: flex;

      li {
        margin: 0;
        display: flex;
        align-items: center;

        button {
          background: transparent !important;
          box-shadow: none !important;
          padding: 0;
          text-transform: uppercase;
          color: $primary-500;
          font-weight: 700;
          line-height: 22px;
          display: block;
          transition: all, 0.4s ease;

          &.current {
            color: $secondary-500;
          }
        }

        a {
          color: $primary-500;
          display: block;
          line-height: 22px;
          font-weight: 700;
          transition: all, 0.4s ease;
          outline: none !important;
        }

        .c-icon {
          margin: 0 7px;
          color: $grey-300;
        }

        &:hover {
          a,
          button {
            color: $secondary-500;
          }
        }
      }
    }
  }

  .menu-button {
    background: transparent !important;
    outline: none !important;
    box-shadow: none !important;
    color: $primary-500;
    padding: 0;
  }
}

@media screen and (max-width: 1610px) {
  .header {
    .logo {
      width: 300px;
    }
  }
}

@media screen and (max-width: 1500px) {
  .header {
    .logo {
      width: 240px;
    }
  }
}

@media screen and (max-width: 1440px) {
  .header {
    .logo {
      width: 240px;
    }
    &--scrolled {
      padding: 12px 0;
    }
    .navigation {
      ul {
        padding: 20px 20px;
        li {
          .c-icon {
            margin: 0 3px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .header {
    .logo {
      display: none;
    }
    .row {
      justify-content: center;
    }
  }
}

@media screen and (max-width: 1026px) {
  .header {
    .logo {
      display: block;
    }
    .row {
      justify-content: space-between;
    }
  }
}
