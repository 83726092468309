.announcement-card {
  border-radius: 40px 10px 40px 40px;
  overflow: hidden;
  border: 1px solid $grey-300;
  height: 100%;
  text-align: start;
  max-width: 554px;

  &-image {
    height: 320px;
    .image {
      width: 100%;
      height: 100%;

      img {
        object-fit: cover;
        object-position: center;
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding: 16px;
    min-height: 244px;

    .date {
      font-size: 12px;
      line-height: 18px;
      font-weight: bold;
      color: $primary-500;
      background-color: $grey-200;
      padding: 4px 10px;
      border-radius: 10px;
    }

    .title {
      font-size: 24px;
      line-height: 37px;
      font-weight: bold;
      color: $secondary-500;
      margin: 0;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .description {
      margin: 0;
      font-size: 16px;
      line-height: 28px;
      font-weight: bold;
      color: $primary-500;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
  .announcement-route-detail {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    background-color: $primary-500;
    border-radius: 4px 20px;
    padding: 10px 40px;
    color: white;
    box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.05);
    margin: 0 0 16px 16px;
  }
}

@media #{$media-xl} {
  .announcement-card {
    &-image {
      height: 264px;
    }

    &-content {
      gap: 12px;
      min-height: 224px;

      .title {
        font-size: 22px;
      }
    }
  }
}

@media #{$media-lg} {
  .announcement-card {
    &-image {
      height: 234px;
    }

    &-content {
      gap: 8px;
      min-height: 224px;

      .title {
        font-size: 20px;
      }
    }
  }
}

@media #{$media-sm} {
  .announcement-card {
    &-image {
      height: 274px;
    }

    &-content {
      min-height: auto;
      gap: 14px;
      .title {
        -webkit-line-clamp: 3;
        line-clamp: 3;
        line-height: 28px;
      }
    }

    .announcement-route-detail {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 6px 34px;
      margin: 16px;
    }
  }
}
