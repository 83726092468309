.socials-share {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 14px;

  &.mobile {
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  &-save {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    background-color: #362e60;
    border-radius: 20px 4px 20px 4px;
    padding: 8px 16px;
    color: #fff;

    .text {
      color: $primary-600;
      font-size: 16px;
      font-weight: bold;
      line-height: 24.8px;
    }

    .save-icon {
      font-size: 20px;
      color: #fff;
    }

    &.share-text-mobile {
      border-radius: 20px 4px 20px 4px;
      padding: 8px 16px;
      border: 1px solid #f1f5f9;
      width: 100%;
      justify-content: center;
      flex: 1;
    }
  }

  &-buttons {
    position: relative;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    border-radius: 20px 4px 20px 4px;
    padding: 8px 16px 8px 16px;
    gap: 6px;

    .text {
      margin-right: 10px;
      font-size: 16px;
      line-height: 24.8px;
      font-weight: bold;
    }

    &.share-text-mobile {
      border-radius: 20px 4px 20px 4px;
      padding: 8px 16px 8px 16px;
      border: 1px solid #f1f5f9;
      flex: 1;
      justify-content: center;
      background-color: $primary-600;

      .text {
        margin-right: 0;
        color: #fff;
      }
    }

    .share-icon {
      position: relative;
      font-size: 34px;
      color: #cbd5e1;
      transition: all 250ms ease-in-out;
      &::before {
        transition: all, 0.4s;
      }
      &:hover {
        &::before {
          color: $secondary-500;
        }
      }
      &.telegram-share {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #cbd5e1;
        border-radius: 50%;
        padding: 6px 6px 6px 4px;

        &::before {
          font-size: 16px;
        }

        &:hover {
          background-color: $secondary-500;

          &::before {
            color: #fff;
          }
        }
      }
    }

    button {
      position: relative;
      .tooltip {
        position: absolute;
        transform: translate(-50%, 120%);
        bottom: 0;
        left: 50%;
        background-color: #362e60;
        color: #fff;
        padding: 6px 10px 10px 10px;
        border-radius: 8px;
        text-wrap: nowrap;
        opacity: 0;
        pointer-events: none;
        transition: all 200ms ease-in-out;
        font-size: 12px;

        &::before {
          content: '';
          display: inline-block;
          position: absolute;
          transform: translate(-50%, -100%) rotate(45deg);
          left: 50%;
          top: 12px;
          background-color: #362e60;
          width: 20px;
          height: 20px;
          border-radius: 4px;
          z-index: -1;
        }
      }

      &:hover .tooltip {
        opacity: 1;
        pointer-events: auto;
        transform: translate(-50%, 140%);
      }

      &:hover .share-icon {
        color: #ed3c6b;
      }
    }

    .close-icon {
      visibility: hidden;
      width: 0;
      opacity: 0;
      transition: all 150ms ease-in-out;

      &.active {
        visibility: visible;
        width: fit-content;
        opacity: 1;
      }
    }

    .share-modal {
      position: absolute;
      text-align: center;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 8px 0;
      border-radius: 20px 4px 20px 4px;
      border: 1px solid #f1f5f9;
      background-color: #fff;
      transform: translateY(90%);
      transition: all 250ms ease-in-out;

      visibility: hidden;
      pointer-events: none;
      opacity: 0;
      z-index: 1;

      &.active {
        transform: translateY(130%);
        visibility: visible;
        pointer-events: all;
        opacity: 1;
        z-index: 1;
      }
    }
  }
}

.social-share-bottom-content {
  padding: 16px;

  .title {
    font-size: 22px;
    font-weight: bold;
    line-height: 27px;
    margin-bottom: 0;
    color: $secondary-500;
  }

  .desc {
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    color: $grey-600;
    margin: 4px 0 0 0;
  }

  .socials {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: flex-start;
    gap: 16px;
    margin-top: 24px;

    &-item {
      display: inline-block;
      text-align: center;

      &-icon {
        padding: 13px;
        border-radius: 12px;
        font-size: 34px;

        &::before {
          color: #fff;
        }
      }

      &-name {
        display: block;
        font-size: 12px;
        font-weight: bold;
        line-height: 18px;
        margin-top: 4px;

        .url-copied {
          .text {
            color: #09913a;
          }
        }
      }

      &.whatsapp {
        .socials-item-icon {
          background-color: #25d366;
          padding-left: 12.5px;
          padding-right: 12.5px;
        }
      }

      &.facebook {
        .socials-item-icon {
          background-color: #1778f2;
          padding-left: 12.5px;
          padding-right: 12.5px;
        }
      }

      &.twitter {
        .socials-item-icon {
          background-color: #15202b;
          font-size: 48px;
          padding: 6px 5.77px;

          &::before {
            color: transparent !important;
          }
        }
      }

      &.telegram {
        .socials-item-icon {
          background-color: #27a7e7;
          font-size: 20px;
          padding: 20px 17.64px;
        }
      }

      &.e-mail {
        .socials-item-icon {
          background-color: #9b8ecf;
          padding-left: 12.5px;
          padding-right: 12.5px;
        }
      }

      &.copy {
        background-color: inherit;
        border: none;
        outline: none;
        color: #000;

        :focus {
          border: none;
          outline: none;
        }

        .socials-item-icon {
          background-color: #fdb3b1;
          font-size: 24px;
          padding: 18px 16.37px;
        }
      }
    }
  }
}
