@mixin appearance($appearance, $color) {
  .form-message--appearance-#{$appearance} {
    color: $color;
  }
}

.form {
  &-header {
    margin-bottom: 1.5rem;
  }

  &-section:not(:first-child) {
    margin-top: 1.5rem;
  }

  &-footer {
    margin-top: 1.5rem;
  }

  &-label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0.5rem;
    display: block;
  }

  &-message {
    font-size: 12px;
    font-weight: 500;
    margin-top: 0.5rem;
    line-height: 16px;
    display: flex;
    align-items: center;

    .icon {
      margin-right: 2px;
    }
  }
}

@include appearance(default, neutral(600));
@include appearance(error, red(400));
@include appearance(help, purple(400));
@include appearance(info, blue(400));
@include appearance(success, green(400));
@include appearance(warning, yellow(400));
