.location-map {
  margin-bottom: 60px;
  margin-top: 60px;
  &-header {
    margin-bottom: 16px;
    .title {
      font-size: 42px;
      font-weight: bold;
      line-height: 58px;
      color: #ed3c6b;
    }

    .place {
      max-width: 372px;
      margin-top: 18px;
      span {
        font-size: 18px;
        font-weight: bold;
        line-height: 28px;
        color: #362e60;
      }
      p {
        margin: 8px 0 0 0;
        font-size: 14px;
        line-height: 21px;
        color: #475569;
      }
    }
  }

  &-content {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 450px;

    .get-direction {
      z-index: 1;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      padding: 10px 40px 12px 40px;
      border-radius: 4px 20px 4px 20px;
      box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.05);
      color: #fff;
      background-color: #362e60;

      span {
        font-size: 14px;
        font-weight: bold;
        line-height: 21px;
      }

      &.custom {
        position: absolute;
        transform: translateX(-50%);
        bottom: 24px;
        left: 50%;
      }

      &.public {
        position: relative;
        margin-top: 24px;
        margin-bottom: 60px;
        transform: translateX(-50%);
        left: 50%;
      }
    }

    .map {
      width: 100%;
      height: 100%;
      iframe,
      .map {
        overflow: hidden;
        background-color: #f1f5f9;
      }
    }

    &::after {
      content: '';
      position: absolute;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      left: 50%;
      top: 0;
      width: 84px;
      height: 84px;
      border-bottom-right-radius: 34%;
      background-color: #fff;
      pointer-events: none;
    }
  }
}

@media #{$media-md} {
  .location-map {
    &-header {
      .title {
        font-size: 32px;
        line-height: 48px;
      }
    }

    &-content {
      .map {
        height: 350px;
      }
    }
  }
}

@media #{$media-sm} {
  .location-map {
    &-header {
      .title {
        font-size: 18px;
        line-height: 27px;
      }
      .place {
        max-width: 100%;
        margin-top: 16px;
      }
    }

    &-content {
      .get-direction {
        span {
          text-wrap: nowrap;
        }
      }

      .map {
        height: 450px;
      }

      &::after {
        width: 64px;
        height: 64px;
      }
    }
  }
}
