.event-card {
  border: 2px solid $grey-200;
  border-radius: 10px 40px 10px 40px;
  overflow: hidden;
  height: 100%;
  transition: all, 0.4s ease-in-out;

  &-image {
    .image {
      width: 100%;
      height: 100%;
      img {
      }
    }
  }

  &-content {
    padding: 24px 18px;

    &-title {
      min-height: 68px;
      font-size: 22px;
      font-weight: bold;
      line-height: 34px;
      color: #362e60;
      margin-top: 12px;
      margin-bottom: 8px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      transition: all, 0.4s ease-in-out;
    }

    &-infos {
      .info {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;

        &.date {
          color: #ed3c6b;
        }

        &.festival {
          font-size: 16px;
          font-weight: bold;
          color: #ed3c6b;

          &::before {
            content: '•';
            display: inline-block;
            text-align: center;
            padding-bottom: 2px;
            color: var(--DN0);
            min-width: 32px;
          }
        }

        &:not(:last-child) {
          margin-bottom: 4px;
        }

        &-text {
          font-size: 14px;
          line-height: 22px;
        }

        .c-icon {
          padding: 8px;
          border-radius: 8px;
          background-color: #f8fafc;
        }
      }
    }
  }

  &:hover {
    border-color: $secondary-500;
    .event-card-content {
      &-title {
        color: $secondary-500;
      }
    }
  }
}

.events-content-labels {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 12px;
  margin-block-start: 0;
  margin-block-end: 0;
  padding: 4px 0;

  .labels-item {
    list-style-position: inside;
    margin: 0;

    span {
      padding: 4px 10px;
      border: 1px solid #f1f5f9;
      font-size: 12px;
      line-height: 18.6px;
      border-radius: 10px;
      font-weight: bold;
      background-color: $grey-200;
      color: $primary-500;
    }

    &:first-child {
      list-style-type: none;
    }

    &.pain {
      span {
        background-color: #f8edf4;
        color: #ed3c6b;
      }
    }

    &.free {
      span {
        background-color: #eff7ec;
        color: #78af64;
      }
    }

    &::marker {
      color: #e2e8f0;
    }
  }
}

@media #{$media-xl} {
  .event-card {
    &-content {
      &-title {
        font-size: 20px;
        line-height: 28px;
        -webkit-line-clamp: 3;
        line-clamp: 3;
      }
    }
  }

  .events-content-labels {
    .labels-item {
      span {
        padding: 4px 6px;
        font-size: 10px;
        line-height: 16.6px;
      }
    }
  }
}

@media #{$media-md} {
  .event-card {
    &-content {
      &-title {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  .events-content-labels {
    .labels-item {
      span {
        padding: 4px 10px;
        font-size: 12px;
        line-height: 18.6px;
      }
    }
  }
}

@media #{$media-sm} {
  .event-card {
    &-content {
      &-title {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}
