@keyframes skeleton {
  100% {
    transform: translateX(100%);
  }
}

.image {
  $parent: &;

  align-items: center;
  border: 0;
  box-sizing: border-box;
  display: inline-flex;
  height: initial;
  justify-content: center;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  padding: 0;
  position: relative;
  vertical-align: top;
  width: initial;

  &-loading {
    background-color: neutral(20);

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      transform: translateX(-100%);
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(transparent),
        color-stop(neutral(20)),
        to(transparent)
      );
      background: linear-gradient(90deg, transparent, neutral(40), transparent);
      animation: skeleton 1s infinite;
    }
  }

  &-loaded {
    #{$parent}-node {
      opacity: 1;
    }
  }

  &-node {
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    display: block;
    object-fit: contain;
    box-sizing: border-box;
    visibility: visible;
    transition: 150ms;
    opacity: 0;

    &:not([src]) {
      visibility: hidden;
    }
  }
}

.broken-image {
  background-color: neutral(0);
  background-position: center center;
  background-image: url('/images/broken-image.svg');
  background-repeat: no-repeat;
  background-size: 25% 25%;
  filter: grayscale(1);
}
