.about-us-video-gallery {
  margin-top: 100px;

  &__videos-scroll-area {
    height: 500vh;
    position: relative;
  }

  &__videos-wrapper {
    position: sticky;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: self-start;
    overflow: visible;
    overflow-x: hidden;
  }

  &__videos {
    white-space: nowrap;


    &__item {
      width: 340px;
      height: min(605px, calc(var(--app-height) - 100px));
      display: inline-block;
      background-color: $grey-200;
      border-radius: 40px;
      overflow: hidden;
      position: relative;
      cursor: pointer;

      .image,
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }



      &:not(:last-child) {
        margin-right: 20px;
      }

    }
  }

  &__modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: var(--app-height);
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;


    video,
    picture,
    .image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

  }
}

@media ($media-md) {
  .about-us-video-gallery {
    &__videos {
      height: 100%;
    }
  }
}