@font-face {
  font-family: 'icomoon';
  src: url('/fonts/icomoon/icomoon.eot');
  src: url('/fonts/icomoon/icomoon.eot') format('embedded-opentype'),
    url('/fonts/icomoon/icomoon.ttf') format('truetype'),
    url('/fonts/icomoon/icomoon.woff') format('woff'),
    url('/fonts/icomoon/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-today:before {
  content: "\e924";
}
.icon-icons8-check:before {
  content: '\e923';
}
.icon-telegram-share-icon:before {
  content: '\e921';
  color: #fff;
}
.icon-copy-page-url:before {
  content: '\e922';
  color: #fff;
}
.icon-menu:before {
  content: '\e91f';
  color: #362e60;
}
.icon-facebook-share-icon:before {
  content: '\e919';
  color: #cbd5e1;
}
.icon-mail-share-icon:before {
  content: '\e91a';
  color: #cbd5e1;
}
.icon-whatsapp-share-icon:before {
  content: '\e91b';
  color: #cbd5e1;
}
.icon-x-share-icon:before {
  content: '\e91c';
  color: rgb(203, 213, 225);
}
.icon-x-share-icon:after {
  content: '\e91d';
  margin-left: -0.962890625em;
  color: rgb(255, 255, 255);
}
.icon-chev-left:before {
  content: '\e911';
}
.icon-arrow-down:before {
  content: '\e900';
}
.icon-arrow-right:before {
  content: '\e901';
}
.icon-arrow-up:before {
  content: '\e902';
}
.icon-calendar:before {
  content: '\e903';
}
.icon-chev-down:before {
  content: '\e904';
}
.icon-chev-right:before {
  content: '\e905';
}
.icon-chev-up:before {
  content: '\e906';
}
.icon-clock:before {
  content: '\e907';
}
.icon-close:before {
  content: '\e908';
}
.icon-dot:before {
  content: '\e909';
}
.icon-facebook:before {
  content: '\e90a';
}
.icon-instagram:before {
  content: '\e90b';
}
.icon-pin:before {
  content: '\e90c';
}
.icon-play:before {
  content: '\e90d';
}
.icon-search-button:before {
  content: '\e90e';
}
.icon-twitter:before {
  content: '\e90f';
}
.icon-youtube:before {
  content: '\e910';
}
.icon-error:before {
  content: '\e912';
}
.icon-warning:before {
  content: '\e913';
}
.icon-keyboard_control:before {
  content: '\e914';
}
.icon-check_box:before {
  content: '\e915';
}
.icon-check_circle:before {
  content: '\e916';
}
.icon-help:before {
  content: '\e917';
}
.icon-info:before {
  content: '\e918';
}
.icon-bookmark:before {
  content: '\e91e';
}
.icon-tik-tok:before {
  content: '\e920';
}
