:root {
  --app-height: 100%;
}

$font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
$code-font-family: Menlo, Monaco, Lucida Console, Liberation Mono,
  DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;

// Sizes
$unit-size: 4px;
$font-size: 14px;
$transition-duration: 100ms;

// Fonts
$font-family: 'GalanoGrotesqueAlt';

// Colors
$primary-100: #e3ddf7;
$primary-200: #c7bdef;
$primary-300: #9b8ecf;
$primary-400: #6c619f;
$primary-500: #362e60;
$primary-600: #282152;
$primary-700: #1d1745;
$primary-800: #130e37;
$primary-900: #0c082e;

$secondary-100: #fedcd8;
$secondary-200: #fdb3b1;
$secondary-300: #fdb3b1;
$secondary-400: #f46b82;
$secondary-500: #ed3c6b;
$secondary-600: #cb2b65;
$secondary-700: #aa1e5e;
$secondary-800: #891354;
$secondary-900: #710b4e;

$grey-100: #f8fafc;
$grey-200: #f1f5f9;
$grey-300: #e2e8f0;
$grey-400: #cbd5e1;
$grey-500: #94a3b8;
$grey-600: #475569;
$grey-700: #334155;
$grey-800: #1e293b;
$grey-900: #0f172a;

$soft-green: #f9fcf8;
$soft-green-dark: #eff7ec;

$soft-blue: #f2fbfd;
$soft-blue-dark: #e4f6fb;

$soft-orange: #fff9f7;
$soft-orange-dark: #fff2ed;

$soft-red: #fcf7fa;
$soft-red-dark: #f8edf4;

$soft-purple: #faf9ff;
$soft-purple-dark: #f4f1ff;

$soft-pink: #fdf4fd;
$soft-pink-dark: #fae6fa;

// font
$text-size: 14px;
$small-text-size: 12px;
$font-weight-100: 100;
$font-weight-200: 200;
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;
$font-weight-800: 800;
$font-weight-900: 900;
$font-weight: $font-weight-400;

// border radius
$border-radius-xl: $unit-size * 8;
$border-radius-lg: $unit-size * 6;
$border-radius-md: $unit-size * 4;
$border-radius-sm: $unit-size * 2;
$border-radius-xs: $unit-size * 1;
$border-radius-none: 0;
$border-radius: $border-radius-xs;

// paddings
$padding-xl: $unit-size * 8;
$padding-lg: $unit-size * 6;
$padding-md: $unit-size * 4;
$padding-sm: $unit-size * 2;
$padding-xs: $unit-size * 1;
$padding: $padding-md;
$padding-none: 0;

// margins
$margin-xl: $unit-size * 8;
$margin-lg: $unit-size * 6;
$margin-md: $unit-size * 4;
$margin-sm: $unit-size * 2;
$margin-xs: $unit-size * 1;
$margin: $margin-md;
$margin-none: 0;

// z-index
$z-index: auto;
$z-index-card: 100;
$z-index-dialog: 300;
$z-index-navigation: 200;
$z-index-layer: 400;
$z-index-blanket: 500;
$z-index-modal: 510;
$z-index-flag: 600;
$z-index-spotlight: 700;
$z-index-tooltip: 800;

// break points
$breakpoints-es: 360px;
$breakpoints-es-min: $breakpoints-es;
$breakpoints-es-max: $breakpoints-es-min - 1px;
$breakpoints-xs: 480px;
$breakpoints-xs-min: $breakpoints-xs;
$breakpoints-xs-max: $breakpoints-xs-min - 1px;
$breakpoints-sm: 768px;
$breakpoints-sm-min: $breakpoints-sm;
$breakpoints-sm-max: $breakpoints-sm-min - 1px;
$breakpoints-md: 1024px;
$breakpoints-md-min: $breakpoints-md;
$breakpoints-md-max: $breakpoints-md-min - 1px;
$breakpoints-lg: 1200px;
$breakpoints-lg-min: $breakpoints-lg;
$breakpoints-lg-max: $breakpoints-lg-min - 1px;
$breakpoints-xl: 1440px;
$breakpoints-xl-min: $breakpoints-lg;
$breakpoints-xl-max: $breakpoints-lg-min - 1px;

$media-es: '(max-width: #{$breakpoints-es})';
$media-lg: '(max-width: #{$breakpoints-lg})';
$media-md: '(max-width: #{$breakpoints-md})';
$media-sm: '(max-width: #{$breakpoints-sm})';
$media-xl: '(max-width: #{$breakpoints-xl})';
$media-xs: '(max-width: #{$breakpoints-xs})';
